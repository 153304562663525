.slick-arrow::before {
  color: #838383;
  font-size: 30px;
  z-index: 1;
}

.card-item:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  cursor: pointer;
}

.slick-next:before,
.slick-prev:before {
  content: '' !important;
}

.next-slick-arrow,
.prev-slick-arrow {
  color: #000000;
  font-size: 48px;
}

.container-slider {
  width: 66%;
  margin-right: auto;
  margin-left: auto;
}

.container-slider-sm {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.container-slider-xs {
  width: 89%;
  margin-right: auto;
  margin-left: auto;
}

.bg-blue {
  background-color: #015c92;
}

.blue {
  color: #015c92;
}

.titleCategory:before,
.titleCategory:after {
  content: '';
  flex: 1 1;
  border-bottom: 2px solid #015c92;
  margin: 20px 20px 20px;
  opacity: 0.2;
}

.cat-width {
  width: 105%;
}

@media (max-width: 640px) {
  .slick-slide {
    margin: 0 -0.4px;
  }
}

.text-img-cat {
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, #000, transparent);
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  color: white;
  font-size: 16px;
  padding: 20px;
  text-align: center;
}

.text-bottom-left {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(to top, #000, transparent);
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  color: white;
  font-size: 16px;
  padding: 20px;
  text-align: center;
}
