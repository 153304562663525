.border-bottom {
  border-color: black;
  border-bottom: 2px;
}
.select-lang {
  background-color: transparent;
  margin-left: 1rem;
  margin-bottom: 1rem;
}
.option-lang {
  background-color: #1f2833;
}
/* .blue {
  color: #015c92;
} */
@media screen and (max-width: 768px) {
  .links-custom-mob {
    position: absolute;
    width: 60%;
    right: 0px;
    height: 120vh;
    background-color: #1f2833;
    overflow-y: hidden;
  }
}

.overlay-side {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
}

.dinar {
  font-weight: bolder;
  cursor: pointer;
}

.h-bor {
  height: 4.1rem;
  border-color: #add1d0;
}

.height-mob-nav {
  height: 4.4rem;
}

.margin-lap {
  margin-bottom: 0.3rem;
}

@media screen and (min-width: 768px) {
  .margin-lap {
    margin-bottom: 1.1rem;
  }
}

.bg-new-color {
  background-color: var(--color-header);
}
.margin-ipxs {
  margin-top: 0.5rem;
  padding-top: 1rem;
}
@media (max-width: 370px) {
  .margin-ipxs {
    margin-top: 0.4rem;
    padding-top: 0.8rem;
  }
  .h-bor {
    height: 3rem;
    border-color: #add1d0;
  }
}
