.home-page-slider {
  -webkit-box-shadow: 0px -3px 24px 7px rgba(10, 0, 0, 0.4);
  -moz-box-shadow: 0px -3px 24px 7px rgba(10, 0, 0, 0.4);
  box-shadow: 0px -3px 24px 7px rgba(0, 0, 0, 0.9);
}
.h-slider {
  height: 10rem;
}

@media (min-width: 768px) {
  .h-slider {
    height: 20rem;
  }
}
