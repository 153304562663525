.label-contact-us {
  text-align: right;
  font-weight: bold;
  display: block;
  width: 100%;
  margin-top: 1.2rem;
  margin-bottom: 0.4rem;
}

.callus-contact-us {
  text-align: start;
  margin-bottom: 1.5rem;
}

.container-contact-us {
  padding: 3rem;
  color: #015c92;
}

.button-contact-us {
  display: inline;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  padding: 0.45rem 0.85rem;
  font-size: 1rem;
  border-radius: 0.37rem;
  color: #fff;
  background-color: #015c92;
  border: 1px solid transparent;
  width: 8rem;
  cursor: pointer;
  margin-top: 0.8rem;
  margin-left: 2rem;
}

.icons-contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 4rem;
}

.details-contact-us {
  background-color: white;
  width: 8rem;
  height: 5rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  border-radius: 0.37rem;
  padding-top: 0.4rem;
}

.description-contact-us {
  font-size: 11px;
  padding-top: 0.8rem;
}

.input-w {
  width: 95%;
}

@media (min-width: 768px) {
  .container-contact-us {
    background-color: var(--color-background);
    width: 80%;
    align-items: center;
    margin: auto;
    border-radius: 0.37rem;
    padding: 2.8rem 4.8rem;
    color: #015c92;
  }
}

@media (min-width: 1024px) {
  .input-w {
    width: 80%;
  }
}

@media (min-width: 1280px) {
  .icons-contact-us {
    display: flex;
    flex-direction: row;
    align-items: space-around;
    justify-content: space-evenly;
    margin: 0 1rem 0 1rem;
  }
}
