:root {
    --color-background: #1f2833;
    --color-footer: #0b0c10;
    --color-header: #0b0c10;
    --color-text: #add1d0;
    --color-secondary: #232b33;
    /*  */
}

@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@300&display=swap');
* {
    font-family: 'Tajawal', sans-serif;
    color: var(--color-text);
    font-weight: bold;
}

.main-cont {
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    background-color: var(--color-background);
}

.container-smoke {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1100px) {
    .container-smoke {
        width: 1100px;
    }
}

@media (min-width: 1300px) {
    .container-smoke {
        width: 1370px;
    }
}

a {
    color: var(--color-text);
}

a:hover {
    color: var(--color-text);
}

.w-counter {
    width: 8.6rem;
}

.show-more {
    color: #085caf;
}

.wrap-text {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (min-width: 1024px) {
    ::-webkit-scrollbar {
        width: 15px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #add1d0;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #add1d0;
    }
}

@media (max-width: 767px) {
    .center-confirm-modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.slick-slide {
    padding: 1rem;
}