.icon-w {
  width: 340px;
  height: 35px;
}
@media (max-width: 370px) {
  .ipxs {
    bottom: 2rem;
    right: 1rem;
  }
}
@media (min-width: 371px) and (max-width: 767px) {
  .ipxs {
    top: 31.5rem;
    right: 1rem;
  }
}
