.animate-logo {
    animation: animate-logo-spin infinite 10s linear;
    pointer-events: none;
}

@keyframes animate-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.bg-blue {
    background-color: #015c92;
}

.blue {
    color: #015c92;
}

.b-border {
    border-color: #015c92;
}

ul li {
    z-index: 2 !important;
}

.status-green {
    background-color: #02998a;
}
