.container {
  padding-top: 1rem !important;
}

.bg-blue {
  background-color: var(--color-background);
}

.blue {
  color: var(--color-text) !important;
}

.b-border {
  border-color: var(--color-text);
}

.width-video {
  width: 22px;
}
