.page-footer {
  background-color: var(--color-footer);
  right: 0;
  bottom: 0;
  left: 0;
  text-align: right;
  position: relative;
  width: 100%;
  direction: rtl;
  -webkit-box-shadow: 0px -3px 24px 7px rgba(10, 0, 0, 0.4);
  -moz-box-shadow: 0px -3px 24px 7px rgba(10, 0, 0, 0.4);
  box-shadow: 0px -3px 24px 7px rgba(0, 0, 0, 0.9);
  color: var(--color-text);
}

a {
  text-decoration: none !important;
}

.footer-social-icon {
  text-align: center;
}

ul {
  margin: 0px;
  padding: 0px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  text-align: center;
  color: var(--color-text);
  line-height: 28px;
}

.footer-social-icon a {
  color: var(--color-text);
  font-size: 16px;
  margin-right: 30px;
}

.footer-social-icon i {
  font-size: 1rem !important;
}

.footer-widget-heading h3 {
  color: var(--color-text);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
}

.footer-widget-heading h3::before {
  content: '';
  position: absolute;
  text-align: center;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #151414;
}

.footer-links {
  text-align: center;
  margin-bottom: 15px;
}

.footer-links a {
  color: var(--color-text);
}

.footer-links :hover {
  color: #fff;
}

@media (min-width: 1024px) {
  .pos-footer-text {
    position: relative;
    text-align: left;
  }
  .footer-logo {
    margin-bottom: 12px;
  }
}
