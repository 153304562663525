@media screen and (max-width: 767px) {
  .model-res {
    width: 100%;
    height: 100%;
    background-color: var(--color-secondary);
  }

  .model-p {
    padding-bottom: 2rem;
  }
}

.bg-model {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-blue {
  background-color: #015c92;
}

.blue {
  color: #015c92;
}

.border-blue {
  border-color: #015c92;
}

.required {
  color: red;
}
.border-conf {
  border-color: #add1d0;
}
