@media (min-width: 768px) {
  .text-l {
    text-align: left;
  }

  .w {
    width: 12rem;
  }
}

@media (min-width: 1100px) {
  .w {
    width: 15rem;
  }
}

@media (min-width: 1300px) {
  .w {
    width: 20rem;
  }
}
.price-txt {
  font-size: 1.4rem;
}
.w-shop {
  width: 20rem;
  padding: 0 4px;
}

.text-l {
  text-align: center;
}

button:focus {
  outline: none;
}

.w-pj {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.bg-blue {
  background-color: #015c92;
}

.blue {
  color: #015c92;
}

.b-border {
  border-color: #015c92;
}

.shopping-body {
  background-color: var(--color-background);
}

.card-deal {
  transition: box-shadow 0.3s;
  border-radius: 18px !important;
  box-shadow: 0px -3px 24px 7px rgba(0, 0, 0, 0.2);
  color: #212529 !important;
}

.child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
