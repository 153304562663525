.svg_icons {
    transform: scale(1.8);
}

.page-link {
    position: relative;
    display: block;
    padding: .8rem .9rem;
    line-height: 1.7rem;
    color: var(--color-text);
    background-color: var(--color-background);
    border: 1px solid #dee2e6;
}

.pagination {
    justify-content: center;
}

ul {
    list-style: none;
    padding: 0;
}

ul.pagination li {
    display: inline-block;
    width: 45px;
    border: 1px solid #e2e2e2;
}

ul.pagination li a {
    display: flex;
    justify-content: space-around;
    font-size: 20px;
}

ul.pagination li.active a {
    justify-content: center;
    background-color: #495057;
}

ul.pagination li.active {
    justify-content: center;
}

ul.pagination li a:hover,
ul.pagination li a.active {
    color: lightblue;
    background-color: #495057;
}

.page-selection {
    width: 48px;
    height: 30px;
    color: #337ab7;
}

.pagination-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}