.border-color {
    color: #dcdee3;
    background-color: #dcdee3;
}

.card-item,
.card-deal {
    transition: box-shadow 0.3s;
    box-shadow: 0px -3px 24px 7px rgba(0, 0, 0, 0.2);
    color: #212529 !important;
}

.card-recommended {
    border-radius: 15px !important;
    padding: 10px 5px !important;
    height: 21rem !important;;
}

.wrap-text-product-recommended {
    overflow: hidden;
    width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card-item:hover,
.card-deal:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.card-recommended-pic-h {
    height: 16rem !important;;
}

@media screen and (max-width: 768px) {
    .h-card {
        height: 18rem;
    }
    .card-recommended {
        height: 14rem !important;
    }
    .card-recommended-pic-h {
        height: 10rem !important;;
    }
}

.h-img {
    height: 8rem;
}

.h-card {
    height: 17rem;
}

@media (min-width: 1023px) {
    .h-card {
        height: 18rem;
    }
}

@media (min-width: 1100px) {
    .h-card {
        height: 17rem;
    }
}

.bg-blue {
    background-color: #015c92;
}

.blue {
    color: #015c92;
}

.titleRecommended:before,
.titleRecommended:after {
    content: '';
    flex: 1 1;
    border-bottom: 2px solid #015c92;
    margin: 20px 35px 20px;
    opacity: 0.2;
}
