@import url(https://fonts.googleapis.com/css2?family=Tajawal:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-arrow::before {
  color: #838383;
  font-size: 30px;
  z-index: 1;
}

.card-item:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  cursor: pointer;
}

.slick-next:before,
.slick-prev:before {
  content: '' !important;
}

.next-slick-arrow,
.prev-slick-arrow {
  color: #000000;
  font-size: 48px;
}

.container-slider {
  width: 66%;
  margin-right: auto;
  margin-left: auto;
}

.container-slider-sm {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.container-slider-xs {
  width: 89%;
  margin-right: auto;
  margin-left: auto;
}

.bg-blue {
  background-color: #015c92;
}

.blue {
  color: #015c92;
}

.titleCategory:before,
.titleCategory:after {
  content: '';
  flex: 1 1;
  border-bottom: 2px solid #015c92;
  margin: 20px 20px 20px;
  opacity: 0.2;
}

.cat-width {
  width: 105%;
}

@media (max-width: 640px) {
  .slick-slide {
    margin: 0 -0.4px;
  }
}

.text-img-cat {
  position: absolute;
  bottom: 0;
  background: linear-gradient(to top, #000, transparent);
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  color: white;
  font-size: 16px;
  padding: 20px;
  text-align: center;
}

.text-bottom-left {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(to top, #000, transparent);
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  opacity: 1;
  color: white;
  font-size: 16px;
  padding: 20px;
  text-align: center;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: 100%;
}

.loader {
    border-top-color: #3498db;
    -webkit-animation: spinner 1.5s linear infinite;
    animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.home-page-slider {
  box-shadow: 0px -3px 24px 7px rgba(0, 0, 0, 0.9);
}
.h-slider {
  height: 10rem;
}

@media (min-width: 768px) {
  .h-slider {
    height: 20rem;
  }
}

.border-color {
    color: #dcdee3;
    background-color: #dcdee3;
}

.card-item,
.card-deal {
    transition: box-shadow 0.3s;
    box-shadow: 0px -3px 24px 7px rgba(0, 0, 0, 0.2);
    color: #212529 !important;
}

.card-recommended {
    border-radius: 15px !important;
    padding: 10px 5px !important;
    height: 21rem !important;;
}

.wrap-text-product-recommended {
    overflow: hidden;
    width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.card-item:hover,
.card-deal:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.card-recommended-pic-h {
    height: 16rem !important;;
}

@media screen and (max-width: 768px) {
    .h-card {
        height: 18rem;
    }
    .card-recommended {
        height: 14rem !important;
    }
    .card-recommended-pic-h {
        height: 10rem !important;;
    }
}

.h-img {
    height: 8rem;
}

.h-card {
    height: 17rem;
}

@media (min-width: 1023px) {
    .h-card {
        height: 18rem;
    }
}

@media (min-width: 1100px) {
    .h-card {
        height: 17rem;
    }
}

.bg-blue {
    background-color: #015c92;
}

.blue {
    color: #015c92;
}

.titleRecommended:before,
.titleRecommended:after {
    content: '';
    flex: 1 1;
    border-bottom: 2px solid #015c92;
    margin: 20px 35px 20px;
    opacity: 0.2;
}

.border-bottom {
  border-color: black;
  border-bottom: 2px;
}
.select-lang {
  background-color: transparent;
  margin-left: 1rem;
  margin-bottom: 1rem;
}
.option-lang {
  background-color: #1f2833;
}
/* .blue {
  color: #015c92;
} */
@media screen and (max-width: 768px) {
  .links-custom-mob {
    position: absolute;
    width: 60%;
    right: 0px;
    height: 120vh;
    background-color: #1f2833;
    overflow-y: hidden;
  }
}

.overlay-side {
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.9);
}

.dinar {
  font-weight: bolder;
  cursor: pointer;
}

.h-bor {
  height: 4.1rem;
  border-color: #add1d0;
}

.height-mob-nav {
  height: 4.4rem;
}

.margin-lap {
  margin-bottom: 0.3rem;
}

@media screen and (min-width: 768px) {
  .margin-lap {
    margin-bottom: 1.1rem;
  }
}

.bg-new-color {
  background-color: var(--color-header);
}
.margin-ipxs {
  margin-top: 0.5rem;
  padding-top: 1rem;
}
@media (max-width: 370px) {
  .margin-ipxs {
    margin-top: 0.4rem;
    padding-top: 0.8rem;
  }
  .h-bor {
    height: 3rem;
    border-color: #add1d0;
  }
}

.icon-w {
  width: 340px;
  height: 35px;
}
@media (max-width: 370px) {
  .ipxs {
    bottom: 2rem;
    right: 1rem;
  }
}
@media (min-width: 371px) and (max-width: 767px) {
  .ipxs {
    top: 31.5rem;
    right: 1rem;
  }
}

.page-footer {
  background-color: var(--color-footer);
  right: 0;
  bottom: 0;
  left: 0;
  text-align: right;
  position: relative;
  width: 100%;
  direction: rtl;
  box-shadow: 0px -3px 24px 7px rgba(0, 0, 0, 0.9);
  color: var(--color-text);
}

a {
  text-decoration: none !important;
}

.footer-social-icon {
  text-align: center;
}

ul {
  margin: 0px;
  padding: 0px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  text-align: center;
  color: var(--color-text);
  line-height: 28px;
}

.footer-social-icon a {
  color: var(--color-text);
  font-size: 16px;
  margin-right: 30px;
}

.footer-social-icon i {
  font-size: 1rem !important;
}

.footer-widget-heading h3 {
  color: var(--color-text);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
}

.footer-widget-heading h3::before {
  content: '';
  position: absolute;
  text-align: center;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #151414;
}

.footer-links {
  text-align: center;
  margin-bottom: 15px;
}

.footer-links a {
  color: var(--color-text);
}

.footer-links :hover {
  color: #fff;
}

@media (min-width: 1024px) {
  .pos-footer-text {
    position: relative;
    text-align: left;
  }
  .footer-logo {
    margin-bottom: 12px;
  }
}

.wrap-text-product-list {
    overflow: hidden;
    width: 90%;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.svg_icons {
    transform: scale(1.8);
}

.page-link {
    position: relative;
    display: block;
    padding: .8rem .9rem;
    line-height: 1.7rem;
    color: var(--color-text);
    background-color: var(--color-background);
    border: 1px solid #dee2e6;
}

.pagination {
    justify-content: center;
}

ul {
    list-style: none;
    padding: 0;
}

ul.pagination li {
    display: inline-block;
    width: 45px;
    border: 1px solid #e2e2e2;
}

ul.pagination li a {
    display: flex;
    justify-content: space-around;
    font-size: 20px;
}

ul.pagination li.active a {
    justify-content: center;
    background-color: #495057;
}

ul.pagination li.active {
    justify-content: center;
}

ul.pagination li a:hover,
ul.pagination li a.active {
    color: lightblue;
    background-color: #495057;
}

.page-selection {
    width: 48px;
    height: 30px;
    color: #337ab7;
}

.pagination-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}
@media (min-width: 768px) {
  .text-l {
    text-align: left;
  }

  .w {
    width: 12rem;
  }
}

@media (min-width: 1100px) {
  .w {
    width: 15rem;
  }
}

@media (min-width: 1300px) {
  .w {
    width: 20rem;
  }
}
.price-txt {
  font-size: 1.4rem;
}
.w-shop {
  width: 20rem;
  padding: 0 4px;
}

.text-l {
  text-align: center;
}

button:focus {
  outline: none;
}

.w-pj {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.bg-blue {
  background-color: #015c92;
}

.blue {
  color: #015c92;
}

.b-border {
  border-color: #015c92;
}

.shopping-body {
  background-color: var(--color-background);
}

.card-deal {
  transition: box-shadow 0.3s;
  border-radius: 18px !important;
  box-shadow: 0px -3px 24px 7px rgba(0, 0, 0, 0.2);
  color: #212529 !important;
}

.child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.label-contact-us {
  text-align: right;
  font-weight: bold;
  display: block;
  width: 100%;
  margin-top: 1.2rem;
  margin-bottom: 0.4rem;
}

.callus-contact-us {
  text-align: start;
  margin-bottom: 1.5rem;
}

.container-contact-us {
  padding: 3rem;
  color: #015c92;
}

.button-contact-us {
  display: inline;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  padding: 0.45rem 0.85rem;
  font-size: 1rem;
  border-radius: 0.37rem;
  color: #fff;
  background-color: #015c92;
  border: 1px solid transparent;
  width: 8rem;
  cursor: pointer;
  margin-top: 0.8rem;
  margin-left: 2rem;
}

.icons-contact-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 4rem;
}

.details-contact-us {
  background-color: white;
  width: 8rem;
  height: 5rem;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  border-radius: 0.37rem;
  padding-top: 0.4rem;
}

.description-contact-us {
  font-size: 11px;
  padding-top: 0.8rem;
}

.input-w {
  width: 95%;
}

@media (min-width: 768px) {
  .container-contact-us {
    background-color: var(--color-background);
    width: 80%;
    align-items: center;
    margin: auto;
    border-radius: 0.37rem;
    padding: 2.8rem 4.8rem;
    color: #015c92;
  }
}

@media (min-width: 1024px) {
  .input-w {
    width: 80%;
  }
}

@media (min-width: 1280px) {
  .icons-contact-us {
    display: flex;
    flex-direction: row;
    align-items: space-around;
    justify-content: space-evenly;
    margin: 0 1rem 0 1rem;
  }
}

.animate-logo {
    -webkit-animation: animate-logo-spin infinite 10s linear;
            animation: animate-logo-spin infinite 10s linear;
    pointer-events: none;
}

@-webkit-keyframes animate-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes animate-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.bg-blue {
    background-color: #015c92;
}

.blue {
    color: #015c92;
}

.b-border {
    border-color: #015c92;
}

ul li {
    z-index: 2 !important;
}

.status-green {
    background-color: #02998a;
}

:root {
    --input-padding-x: 1.5rem;
    --input-padding-y: .75rem;
}


.card-signin {
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
    max-width: 750px;
    margin: auto !important;

}

.card-signin .card-title {
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 1.5rem;
}

.card-signin .card-body {
    padding: 2rem;

}

.form-signin .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    transition: all 0.2s;
}

.form-label-group {
    position: relative;
    margin-bottom: 1rem;
}

.form-label-group input {
    height: auto;
    border-radius: 2rem;
}

.form-label-group > input,
.form-label-group > label {
    padding: .75rem 1.5rem;
    padding: var(--input-padding-y) var(--input-padding-x);
}

.form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: .25rem;
    transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
    color: transparent;
}

.form-label-group input:-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::placeholder {
    color: transparent;
}

.form-label-group input:not(:-ms-input-placeholder) {
    padding-top: calc(.75rem + .75rem * (2 / 3));
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) {
    padding-top: calc(.75rem + .75rem * (2 / 3));
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:-ms-input-placeholder) ~ label {
    padding-top: calc(.75rem / 3);
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
}

.form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(.75rem / 3);
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(.75rem / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777;
}


/* Fallback for Edge
-------------------------------------------------- */

@supports (-ms-ime-align: auto) {
    .form-label-group > label {
        display: none;
    }

    .form-label-group input::-ms-input-placeholder {
        color: #777;
    }
}

/* Fallback for IE
-------------------------------------------------- */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .form-label-group > label {
        display: none;
    }

    .form-label-group input:-ms-input-placeholder {
        color: #777;
    }
}
.container {
  padding-top: 1rem !important;
}

.bg-blue {
  background-color: var(--color-background);
}

.blue {
  color: var(--color-text) !important;
}

.b-border {
  border-color: var(--color-text);
}

.width-video {
  width: 22px;
}

:root {
    --color-background: #1f2833;
    --color-footer: #0b0c10;
    --color-header: #0b0c10;
    --color-text: #add1d0;
    --color-secondary: #232b33;
    /*  */
}
* {
    font-family: 'Tajawal', sans-serif;
    color: #add1d0;
    color: var(--color-text);
    font-weight: bold;
}

.main-cont {
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    background-color: #1f2833;
    background-color: var(--color-background);
}

.container-smoke {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 1100px) {
    .container-smoke {
        width: 1100px;
    }
}

@media (min-width: 1300px) {
    .container-smoke {
        width: 1370px;
    }
}

a {
    color: #add1d0;
    color: var(--color-text);
}

a:hover {
    color: #add1d0;
    color: var(--color-text);
}

.w-counter {
    width: 8.6rem;
}

.show-more {
    color: #085caf;
}

.wrap-text {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (min-width: 1024px) {
    ::-webkit-scrollbar {
        width: 15px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px grey;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #add1d0;
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #add1d0;
    }
}

@media (max-width: 767px) {
    .center-confirm-modal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.slick-slide {
    padding: 1rem;
}
@media screen and (max-width: 767px) {
  .model-res {
    width: 100%;
    height: 100%;
    background-color: var(--color-secondary);
  }

  .model-p {
    padding-bottom: 2rem;
  }
}

.bg-model {
  background-color: rgba(0, 0, 0, 0.7);
}

.bg-blue {
  background-color: #015c92;
}

.blue {
  color: #015c92;
}

.border-blue {
  border-color: #015c92;
}

.required {
  color: red;
}
.border-conf {
  border-color: #add1d0;
}

.error-text {
    font-size: 130px;
}

@media (min-width: 768px) {
    .error-text {
        font-size: 220px;
        color: var(--color-text);
    }
}
.dropdown:hover .dropdown-menu {
  display: block;
}
